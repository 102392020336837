<template>
  <div>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :dense="true"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :param="popupParam"
          @insertSopInfo="updateMode"
        />
      </template>
    </c-tab>
  </div>
</template>
1
<script>
export default {
  name: 'sop-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmSopId: '',
        tabpage: 'sopInfo',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'sopInfo',
      tabItems: [
        { name: 'sopInfo', icon: 'info', label: '작업정보', class:'tab-orange', component: () => import(`${'./sopInfo.vue'}`) },
      ],
      addTabItems: [
        { name: 'sopStep', icon: 'do_not_step', label: '작업단계', component: () => import(`${'./sopStep.vue'}`) },
        { name: 'sopStepRisk', icon: 'new_releases', label: '잠재위험성별 원인/결과', component: () => import(`${'./sopStepRisk.vue'}`) },
        { name: 'sopReport', icon: 'table_view', label: '표준작업정보 요약', class:'tab-lightblue', component: () => import(`${'./sopReport.vue'}`) }
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  watch: {
  },
  methods: {
    init() {
      if (this.popupParam.tabpage !== undefined && this.popupParam.tabpage != '') {
        this.tab = this.popupParam.tabpage;
      }
      if (this.popupParam.mdmSopId != '') {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    updateMode(_mdmSopId) {
      this.popupParam.mdmSopId = _mdmSopId;
      this.tabItems = this.tabItems.concat(this.addTabItems);
    }
  }
};
</script>